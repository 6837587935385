import React, { useState } from "react"


export default () => {
  const [state, setState] = useState({
    food: {
      name: "Ingen fil vald",
      file: null,
    },
    drinks: {
      name: "Ingen fil vald",
      file: null,
    }
  });

  const onClick = () => {
    let data = new FormData();
    data.append("food", state.food.file);
    data.append("drinks", state.drinks.file);

    fetch("/menu/upload", {
      method: "POST",
      body: data,
    })
      .then(res => {
        if (res.status !== 200) {
          if (res.status > 400 && res.status < 500) {
            alert("Fel vid uppladdning av menyn, prova logga in igen: " + res.status)
            window.location.href = "/loggain"

          } else if (res.status === 400) {
            alert("Fel vid uppladdning av menyn, dålig fil se till att det är en pdf fil. Kod: " + res.status)
          } else
            alert("Fel vid uppladdning av menyn: " + res.status)
        } else
          alert("Menyn har blivit uppladdad")
      })
      .catch(err => console.log(err))
  }

  return (
    <div>
      <div className="file has-name">
        <label className="file-label">
          <input className="file-input" type="file" name="food" onChange={(e) => {
            setState({
              ...state,
              food: {
                name: e.target.files[0].name,
                file: e.target.files[0]
              }
            })
          }} />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label">
              Välj fil för mat meny
            </span>
          </span>
          <span className="file-name">
            {state.food.name}
          </span>
        </label>
      </div>

      <div className="file has-name">
        <label className="file-label">
          <input className="file-input" type="file" name="drinks" onChange={(e) => {
            setState({
              ...state,
              drinks: {
                name: e.target.files[0].name,
                file: e.target.files[0]
              }
            })
          }} />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label">
              Välj fil för dryck meny
            </span>
          </span>
          <span className="file-name">
            {state.drinks.name}
          </span>
        </label>
      </div>

      <button onClick={onClick} className="button">Ladda up</button>
    </div>
  )
}
